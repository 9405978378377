import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { request, gql } from 'graphql-request'

interface SubgraphStatus {
  subgraph: string
  synced: boolean
  fatalError?: {
    message: string
  }
  nonFatalErrors?: {
    message: string
  }[]
  chainId: string
}

const subgraphs: Record<number, string> = {
  43114: 'QmR43yHfTCTm1ZYQUqAJSJxkdnxSwuwB2YqqLNFA29Gr1M',
  1: 'QmcvwVLqhqpztw4qAt1quWJAYMHYWrf2dixLc5fzWJ16ex',
  137: 'QmVgApxrquC7MCNHzJTgEf7o7S7spsr1jED9DovxDK7z3u',
  56: 'QmY28kHoUNNu3BEbA3cpdyrdZdYiAcmrbXShXbDPDEyFTt',
  42161: 'QmWk9wBY2N2H1vLKdCQpXbWoQxEWs4CYodFnnwLuP77HFi',
  10: 'QmSg2fQCQk14CjtubH8kBbVgtZe7LNJyCmyeXDcmdBSxMB',
  100: 'QmSUDRgVzRh9HwH5bVimhx73sthdz6PziM8kVrmntRGfoY',
  1313161554: 'QmTpWyrXp9q9xPnLZfYwoNGVNUVUTK8NfZ4j4ZECgfmGHi',
  250: 'QmT1e1zPf7FNEBAiGHh8deSbHh6vLogqYPMB1bmpxZcLGu',
  8453: 'QmYP2NBm5x37QR3owjJEefELLzVyjVdTRd7cMpiaCzQoHb',
}

const useSubgraphs = (): UseQueryResult<SubgraphStatus[]> => {
  return useQuery<SubgraphStatus[]>({
    queryKey: ['useSubgraphs'],
    queryFn: () => fetchAllTasks(),
  })
}

const fetchSubgraph = async (chainId: number): Promise<SubgraphStatus | null> => {
  try {
    const data = await request<{ indexingStatuses: SubgraphStatus[] }>(
      'https://api.thegraph.com/index-node/graphql',
      gql`
        query Subgraphs($subgraphs: [String!]!) {
          indexingStatuses(subgraphs: $subgraphs) {
            subgraph
            synced
            health
            node
            fatalError {
              message
            }
            nonFatalErrors {
              message
            }
          }
        }
      `,
      { subgraphs: [subgraphs[chainId]] }
    )
    return data.indexingStatuses.length > 0 ? { ...data.indexingStatuses[0], chainId: chainId.toString() } : null

  } catch (error) {
    console.error(`Error fetching subgraph for chain ${chainId}:`, error)
    return null
  }
}


const fetchAllTasks = async (): Promise<SubgraphStatus[]> => {
  const chainIds = Object.keys(subgraphs).map(Number)

  const results = await Promise.all(chainIds.map(fetchSubgraph))

  return results.filter((result): result is SubgraphStatus => result !== null)
}

export default useSubgraphs
