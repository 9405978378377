import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import Select from 'react-select'
import { ButtonViolet, Loader, selectStyles } from '../utils/styles'
import { CalendarItem } from '../components/calendar/CalendarItem'
import { useGeneralTimelocks } from '../hooks/useGeneralTimelocks'
import { TimelockResponse } from 'API'

// Funciones para obtener el primer y último día del mes
const getFirstDayOfMonth = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), 1)
}

const getLastDayOfMonth = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0)
}

const getDaysInMonth = (date: Date): Date[] => {
  const days = []
  const firstDayOfMonth = getFirstDayOfMonth(date)
  const lastDayOfMonth = getLastDayOfMonth(date)

  for (let i = firstDayOfMonth.getDate(); i <= lastDayOfMonth.getDate(); i++) {
    days.push(new Date(date.getFullYear(), date.getMonth(), i))
  }

  const firstDayIndex = firstDayOfMonth.getDay()
  if (firstDayIndex !== 0) {
    for (let i = firstDayIndex - 1; i >= 0; i--) {
      days.unshift(new Date(firstDayOfMonth.getFullYear(), firstDayOfMonth.getMonth(), -i))
    }
  }

  const lastDayIndex = lastDayOfMonth.getDay()
  if (lastDayIndex !== 6) {
    for (let i = 1; i <= 6 - lastDayIndex; i++) {
      days.push(new Date(lastDayOfMonth.getFullYear(), lastDayOfMonth.getMonth(), lastDayOfMonth.getDate() + i))
    }
  }

  return days
}

export default function TimelockCalendar() {
  const [currentDate, setCurrentDate] = useState(new Date())
  const [dateRange, setDateRange] = useState<Date[]>([])
  const [selectedEnvironment, setSelectedEnvironment] = useState<string | null>(null)
  const { data, isLoading } = useGeneralTimelocks()

  useEffect(() => {
    const days = getDaysInMonth(currentDate)
    setDateRange(days)
  }, [currentDate])

  const getUniqueEnvironments = (): { value: string | null; label: string }[] => {
    if (!data) return []
    const uniqueEnvironments = [...new Set(data.map((timelock) => timelock.environment))].filter((env) => env)
    const environmentOptions = uniqueEnvironments.map((env) => ({ value: env, label: env }))
    return [{ value: null, label: 'All environments' }, ...environmentOptions]
  }

  const formatDate = (date: Date): string => {
    return date.toISOString().split('T')[0]
  }

  const isToday = (date: Date): boolean => {
    const today = new Date()
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    )
  }

  const moveMonth = (months: number) => {
    const newDate = new Date(currentDate)
    newDate.setMonth(currentDate.getMonth() + months)
    setCurrentDate(newDate)
  }

  const filterTimelocksForDate = (date: string, allTimelocks: TimelockResponse[]) => {
    const targetDate = new Date(date)
    return allTimelocks.filter((timelock) => {
      const sameDay = moment(targetDate).format('YYYY-MM-DD') === moment(timelock.end).format('YYYY-MM-DD')
      const matchesEnvironment = selectedEnvironment ? timelock.environment === selectedEnvironment : true
      return sameDay && matchesEnvironment
    })
  }

  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

  return (
    <CalendarContainer>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <SelectContainer>
            <Select
              styles={selectStyles}
              options={getUniqueEnvironments()}
              onChange={(option) => setSelectedEnvironment(option?.value || null)}
              isClearable
              placeholder="Filter by environment"
            />
          </SelectContainer>
          <Header>
            <ButtonViolet onClick={() => moveMonth(-1)}>Previous</ButtonViolet>
            <h2>{currentDate.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</h2>
            <ButtonViolet onClick={() => moveMonth(1)}>Next</ButtonViolet>
          </Header>
          <WeekHeader>
            {daysOfWeek.map((day, index) => (
              <div key={index}>{day}</div>
            ))}
          </WeekHeader>
          <Grid>
            {dateRange.map((date, index) => (
              <DateCell key={index}>
                <DayNumber istoday={isToday(date).toString()}>{date.getDate()}</DayNumber>
                {data && (
                  <ScrollArea>
                    {filterTimelocksForDate(date.toString(), data).length > 0 && (
                      <>
                        {filterTimelocksForDate(date.toString(), data)
                          .sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime())
                          .map((timelock, index) => (
                            <TaskContainer key={index}>
                              <CalendarItem timelock={timelock}>{timelock.environment}</CalendarItem>
                            </TaskContainer>
                          ))}
                      </>
                    )}
                  </ScrollArea>
                )}
              </DateCell>
            ))}
          </Grid>
        </>
      )}
    </CalendarContainer>
  )
}

const CalendarContainer = styled.div`
  padding: 16px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`

const SelectContainer = styled.div`
  margin-bottom: 16px;
  max-width: 300px;
`

const WeekHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  font-weight: bold;
  margin-bottom: 8px;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 16px;
`

const DateCell = styled.div`
  padding: 8px;
  border: 1px solid #323244;
  border-radius: 8px;
  background-color: #323244;
  color: #fff;
  height: 200px;
  overflow: hidden;
  position: relative;
`
const ScrollArea = styled.div`
  position: relative;
  overflow-y: auto;
  max-height: 12rem;
  padding: 4px;
  top: 30px;
`

const DayNumber = styled.div<{ istoday: string }>`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ istoday }) => (istoday === 'true' ? '#f91616' : 'transparent')};
  color: #ffffff;
  font-weight: bold;
  position: absolute;
  top: 8px;
  left: 8px;
`

const TaskContainer = styled.div`
  margin-bottom: 8px;
  font-size: 0.875rem;
`

const StyledSelect = styled(Select)`
  .Select__control {
    min-width: 300px;
    border-radius: 10px;
    color: white;
    background: #2a2b3d;
    cursor: pointer;
    border-color: hsl(236.84deg 18.45% 20.2%);
  }

  .Select__single-value {
    color: white;
  }
  .Select__menu {
    color: white;
    background: #2a2b3d;
  }
  .Select__option {
    &:hover {
      background-color: #6f5ce6;

      &.Select__option--is-focused {
        background-color: #9487e5;
      }
    }
  }
`
