import React, { createContext, useState, useEffect, ReactNode } from 'react'
import axios from 'axios'
import { ChainSubgraphsResponse } from '../types/API'
import { useAuth } from '../context/AuthContext'
import { useApi } from '../hooks/useApi'

interface SubgraphsProviderProps {
  children: ReactNode
}

const SubgraphsContext = createContext<ChainSubgraphsResponse>({})

const URL = process.env.REACT_APP_SERVER_BASE_URL

export const SubgraphsProvider: React.FC<SubgraphsProviderProps> = ({ children }) => {
  const { callApi } = useApi()
  const { user } = useAuth() 
  const [data, setData] = useState<ChainSubgraphsResponse>({})

  const fetchData = async () => {
    try {
      const response = await callApi('/relayer-executor/subgraphs')
      setData(response)
    } catch (error) {
      console.error('Error fetching the subgraphs url')
    }
  }

  useEffect(() => {
    if (user) {
      fetchData()
    }
  }, [user])

  return <SubgraphsContext.Provider value={data}>{children}</SubgraphsContext.Provider>
}

export default SubgraphsContext
